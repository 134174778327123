import type { MapCoordinates } from '@freelancer/datastore/core';
import { generateId } from '@freelancer/datastore/testing/helpers';
import type { UpgradeType } from '@freelancer/ui/upgrade-tag';
import type {
  Project,
  PartialProjectBidStats,
} from '../projects/projects.model';
import type { GenerateProjectOptions } from '../projects/projects.seed';
import { generateProjectObject } from '../projects/projects.seed';
import type { Skill } from '../skills/skills.model';
import { phpSkill, pythonSkill } from '../skills/skills.seed';
import type { SearchActiveProject } from './search-active-projects.model';
import { transformBidTimeLeft } from './search-active-projects.transformers';

export type GenerateSearchActiveProjectOptions = GenerateProjectOptions & {
  readonly upgradeTypes?: readonly UpgradeType[];
  readonly skills?: readonly Skill[];
  readonly bidCount?: number;
  readonly bidStats?: PartialProjectBidStats;
  readonly bidPeriod?: number;
  readonly description?: string;
  readonly clientCountry?: string;
  readonly clientReputation?: {
    readonly rating: number;
    readonly reviews: number;
  };
  readonly searchCoordinates?: MapCoordinates;
};

export function generateSearchActiveProjectObject(
  options: GenerateSearchActiveProjectOptions,
): SearchActiveProject {
  const project = generateProjectObject(options);

  return {
    ...project,
    ...generateSearchActiveProjectDetails({
      ...options,
      projectId: project.id,
    }),
  };
}

export type GenerateSearchActiveProjectsOptions = GenerateProjectOptions & {
  readonly titles: readonly string[];
  readonly upgradeTypes?: readonly UpgradeType[];
  readonly skills?: readonly Skill[];
  readonly bidCount?: number;
  readonly bidStats?: PartialProjectBidStats;
  readonly bidPeriod?: number;
  readonly description?: string;
  readonly clientCountry?: string;
  readonly clientReputation?: {
    readonly rating: number;
    readonly reviews: number;
  };
  readonly searchCoordinates?: MapCoordinates;
};

export function generateSearchActiveProjectObjects(
  options: GenerateSearchActiveProjectsOptions,
): readonly SearchActiveProject[] {
  return options.titles.map(title => ({
    ...generateProjectObject({ title, ...options }),
    ...generateSearchActiveProjectDetails(options),
  }));
}

function generateSearchActiveProjectDetails({
  bidCount = 0,
  bidPeriod = 7,
  budget,
  clientCountry = 'us',
  clientReputation,
  description = 'I need a new website for my coffee shop This is a project description with more than 30 characters for opening for bidding.',
  searchCoordinates,
  skills = [phpSkill(), pythonSkill()],
  timeSubmitted = Date.now() / 1000,
  upgradeTypes = [],
  projectId,
}: GenerateSearchActiveProjectOptions): Omit<
  SearchActiveProject,
  keyof Project
> {
  return {
    bidCount,
    bidTimeLeft: transformBidTimeLeft(bidPeriod, timeSubmitted),
    clientCountry,
    country: undefined,
    clientReputation,
    description,

    // Flatten the budget object.
    maximumBudget: budget?.maximum ?? Number.MAX_SAFE_INTEGER,
    minimumBudget: budget?.minimum ?? 0,
    projectId: projectId ?? generateId(),
    searchCoordinates,
    skills,
    skillIds: skills.map(job => job.id),
    upgradeNames: upgradeTypes,
  };
}
